import React from 'react';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { PromoAutomation, PromoAutomationEventType } from 'src/api/v1-api';
import { MdEdit, MdMoreVert, MdFileCopy, MdOutlineExposurePlus1 } from 'react-icons/md';
import { capitalize } from 'lodash';
import { FaCheck, FaTrash } from 'react-icons/fa';
import { FaXmark, FaRegClock } from 'react-icons/fa6';
import DuplicatePromoAutomationModal from '../duplicate-promo-automation-modal';
import MobileRowLabel from 'src/components/molecules/mobile-row-label';

interface PromoAutomationsTableProps {
  promoAutomations: PromoAutomation[];
  editPromoAutomation: (promoAutomation: PromoAutomation) => void;
  onUpdatePromoAutomation: (promoAutomation: Partial<PromoAutomation>) => void;
  onDeletePromoAutomation: (promoAutomation: PromoAutomation) => void;
  onDuplicatePromoAutomation: (promoAutomation: PromoAutomation, categoryId: number) => void;
}

const PromoAutomationsTable = ({
  promoAutomations,
  editPromoAutomation,
  onUpdatePromoAutomation,
  onDeletePromoAutomation,
  onDuplicatePromoAutomation,
}: PromoAutomationsTableProps) => {
  const [selectedPromoAutomation, setSelectedPromoAutomation] =
    React.useState<PromoAutomation | null>(null);
  const isMobileView = useBreakpointValue({ base: true, lg: false });
  const {
    isOpen: isDuplicateModalOpen,
    onOpen: onOpenDuplicateModal,
    onClose: onCloseDuplicateModal,
  } = useDisclosure();

  const getAutomationEventICon = (promoAutomations: PromoAutomation) => {
    switch (promoAutomations.automation_event_type) {
      case PromoAutomationEventType.CRON_SOCIAL_NETWORK:
        return (
          <FaRegClock
            display={'inline'}
            style={{ verticalAlign: 'middle', display: 'inline', marginLeft: '4px' }}
          />
        );
      case PromoAutomationEventType.EVERY_LISTING_PUBLICATION:
        return (
          <MdOutlineExposurePlus1
            fontSize={18}
            style={{ verticalAlign: 'middle', display: 'inline', marginLeft: '4px' }}
          />
        );
      default:
        return null;
    }
  };

  const getScheduleLabel = (promoAutomation: PromoAutomation) => {
    if (promoAutomation.automation_event_type !== PromoAutomationEventType.CRON_SOCIAL_NETWORK) {
      return 'n/a';
    }

    let label = '';
    if (
      promoAutomation.schedule?.days?.length === 1 &&
      promoAutomation.schedule?.days[0].toLowerCase().includes('daily')
    ) {
      label = 'Daily';
    }

    if (
      promoAutomation.schedule?.days?.length === 1 &&
      !promoAutomation.schedule?.days[0].toLowerCase().includes('daily')
    ) {
      label = 'Weekly';
    }

    if (label === '') {
      label = 'Custom';
    }

    return `${label} ${promoAutomation.schedule?.time?.split(',')[1]}`;
  };

  const togglePromoAutomationStatus = (promoAutomation: PromoAutomation) => {
    onUpdatePromoAutomation({
      ...promoAutomation,
      status: promoAutomation.status === 'active' ? 'disabled' : 'active',
    });
  };

  const truncateText = (text: string, length: number) => {
    if (text.length <= length) {
      return text;
    }

    return `${text.substring(0, length)}...`;
  };

  const renderTemplateLabel = (promoAutomation: PromoAutomation) => {
    if (promoAutomation.text_only) {
      return (
        <>
          <Text>Captions</Text>
          <Badge p={1} rounded="lg" colorScheme="cyan" w="fit-content">
            Text
          </Badge>
        </>
      );
    }

    if (promoAutomation.templates.length > 1) {
      return 'Multiple';
    }

    const template = promoAutomation.templates[0];
    return template.use_static_image ? (
      'Static Image'
    ) : (
      <>
        <Text>
          {isMobileView
            ? template.global_promo_media_type.name
            : truncateText(template.global_promo_media_type.name, 26)}
        </Text>
        <Badge p={1} rounded="lg" colorScheme="cyan" w="fit-content">
          {template.global_promo_media_type.media_type}
        </Badge>
      </>
    );
  };

  const duplicatePromoAutomation = (promoAutomation: PromoAutomation) => {
    setSelectedPromoAutomation(promoAutomation);
    onOpenDuplicateModal();
  };

  return (
    <Flex direction="column" width="100%">
      <Flex
        py={4}
        w="100%"
        borderBottom="1px solid"
        borderColor="gray.200"
        gap={4}
        display={{ base: 'none', lg: 'flex' }}
        color="gray.600"
        textTransform="uppercase"
        fontSize="xs"
        fontWeight="bold"
      >
        <Flex w="20%" pl={4}>
          Category
        </Flex>
        <Flex w="20%">Nickname</Flex>
        <Flex w="20%">Template(s)</Flex>
        <Flex w="10%">Schedule</Flex>
        <Flex w="15%">Platforms</Flex>
        <Flex w="5%" justifyContent="end" pr={4}>
          Actions
        </Flex>
      </Flex>
      {promoAutomations.map((promoAutomation) => (
        <Flex
          key={promoAutomation.id}
          flexDirection={{ base: 'column', lg: 'row' }}
          w="100%"
          borderBottom="1px solid"
          borderColor="gray.200"
          py={{ base: 6, lg: 2 }}
          gap={4}
          alignItems="flex-start"
        >
          <Flex w={{ lg: '20%' }} pl={{ base: 0, lg: 4 }} direction="column">
            <MobileRowLabel label="category" />
            <Flex direction={{ base: 'row', lg: 'column' }} gap={2} alignItems={'flex-start'}>
              <Box display={'inline'}>
                {promoAutomation.entity_type_object.name}
                {getAutomationEventICon(promoAutomation)}
              </Box>
              <Badge
                p={1}
                rounded="lg"
                colorScheme={promoAutomation.status === 'active' ? 'green' : 'red'}
                w="fit-content"
              >
                {promoAutomation.status}
              </Badge>
            </Flex>
          </Flex>
          <Flex w={{ lg: '20%' }} direction="column">
            <MobileRowLabel label="Nickname" />
            {promoAutomation.nickname}
          </Flex>
          <Flex w={{ lg: '20%' }} direction="column">
            <MobileRowLabel label="template" />
            <Flex direction={{ base: 'row', lg: 'column' }} gap={2} alignItems={'flex-start'}>
              {renderTemplateLabel(promoAutomation)}
            </Flex>
          </Flex>
          <Flex w={{ lg: '10%' }} direction="column">
            <MobileRowLabel label="schedule" />
            {getScheduleLabel(promoAutomation)}
          </Flex>
          <Flex w={{ lg: '15%' }} direction="column">
            <MobileRowLabel label="networks" />
            {promoAutomation.networks
              .map((network) => capitalize(network.split(':')[0]))
              .join(', ')}
          </Flex>
          <Flex display={{ base: 'flex', lg: 'none' }} direction="row" gap={2} flexWrap={'wrap'}>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => togglePromoAutomationStatus(promoAutomation)}
              leftIcon={promoAutomation.status === 'active' ? <FaXmark /> : <FaCheck />}
            >
              {promoAutomation.status === 'active' ? 'Disable' : 'Enable'}
            </Button>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => editPromoAutomation(promoAutomation)}
              leftIcon={<MdEdit />}
            >
              Edit
            </Button>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => duplicatePromoAutomation(promoAutomation)}
              leftIcon={<MdFileCopy />}
            >
              Duplicate
            </Button>
            <Button
              size="sm"
              colorScheme="gray"
              onClick={() => onDeletePromoAutomation(promoAutomation)}
              leftIcon={<FaTrash />}
            >
              Remove
            </Button>
          </Flex>
          <Flex w="5%" justifyContent="end" pr={4} display={{ base: 'none', lg: 'flex' }}>
            <Menu>
              <MenuButton as={IconButton} aria-label="Actions" icon={<MdMoreVert />} />
              <MenuList>
                <MenuItem
                  icon={promoAutomation.status === 'active' ? <FaXmark /> : <FaCheck />}
                  onClick={() => togglePromoAutomationStatus(promoAutomation)}
                >
                  {promoAutomation.status === 'active' ? 'Disable' : 'Enable'}
                </MenuItem>
                <MenuItem icon={<MdEdit />} onClick={() => editPromoAutomation(promoAutomation)}>
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<MdFileCopy />}
                  onClick={() => duplicatePromoAutomation(promoAutomation)}
                >
                  Duplicate
                </MenuItem>
                <MenuItem
                  icon={<FaTrash />}
                  onClick={() => onDeletePromoAutomation(promoAutomation)}
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      ))}
      <DuplicatePromoAutomationModal
        isOpen={isDuplicateModalOpen}
        onClose={onCloseDuplicateModal}
        promoAutomation={selectedPromoAutomation}
        onDuplicatePromoAutomation={onDuplicatePromoAutomation}
      />
    </Flex>
  );
};

export default PromoAutomationsTable;
